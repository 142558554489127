import React from "react"

export default function HeroFeatureComponent() {
  return (
    <>
      <div className="grid grid-cols-4 gap-6 gap-y-12 lg:grid-cols-12">
        <div className="col-span-4">
          {/*          <!-- Component: Feature item with icon and text --> */}
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="flex items-center text-emerald-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center w-full min-w-0 gap-0 text-base">
              <p className="text-slate-500">
                <strong className="font-medium text-slate-700">
                  Responsive Design:
                </strong>{" "}
                wind UI ensures your web application looks and functions
                flawlessly.
              </p>
            </div>
          </div>
          {/*          <!-- End Feature item with icon and text --> */}
        </div>
        <div className="col-span-4">
          {/*          <!-- Component: Feature item with icon and text --> */}
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="flex items-center text-emerald-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center w-full min-w-0 gap-0 text-base">
              <p className="text-slate-500">
                <strong className="font-medium text-slate-700">
                  Effortless Customization:
                </strong>{" "}
                Easily customize the library's CSS to match your brand's unique
                aesthetics without the need for extensive coding.
              </p>
            </div>
          </div>
          {/*          <!-- End Feature item with icon and text --> */}
        </div>
        <div className="col-span-4 ">
          {/*          <!-- Component: Feature item with icon and text --> */}
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="flex items-center text-emerald-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center w-full min-w-0 gap-0 text-base">
              <p className="text-slate-500">
                <strong className="font-medium text-slate-700">
                  Community-Driven Support:
                </strong>{" "}
                Join a thriving community of developers and get access to
                regular updates, tutorials, and responsive support for your
                projects.
              </p>
            </div>
          </div>
          {/*          <!-- End Feature item with icon and text --> */}
        </div>
      </div>
    </>
  )
}
