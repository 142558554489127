import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"
import ogImage from "../../../images/seo/features/features.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
// Icon left
import FeatureIconLeftTextPreview from "../../../library/components/feature/react/_preview/icon-left/icon-text"
import FeatureIconLeftTitleTextPreview from "../../../library/components/feature/react/_preview/icon-left/icon-title-text"
import FeatureIconLeftSquareTextPreview from "../../../library/components/feature/react/_preview/icon-left/square-icon-text"
import FeatureIconLeftSquareTitleTextPreview from "../../../library/components/feature/react/_preview/icon-left/square-icon-title-text"

// Icon top center
import FeatureIconTopCenterTextPreview from "../../../library/components/feature/react/_preview/icon-top-center/icon-text"
import FeatureIconTopCenterTitleTextPreview from "../../../library/components/feature/react/_preview/icon-top-center/icon-title-text"
import FeatureIconTopCenterSquareTextPreview from "../../../library/components/feature/react/_preview/icon-top-center/square-icon-text"
import FeatureIconTopCenterSquareTitleTextPreview from "../../../library/components/feature/react/_preview/icon-top-center/square-icon-title-text"

// Icon top left
import FeatureIconTopLeftTextPreview from "../../../library/components/feature/react/_preview/icon-top-left/icon-text"
import FeatureIconTopLeftTitleTextPreview from "../../../library/components/feature/react/_preview/icon-top-left/icon-title-text"
import FeatureIconTopLeftSquareTextPreview from "../../../library/components/feature/react/_preview/icon-top-left/square-icon-text"
import FeatureIconTopLeftSquareTitleTextPreview from "../../../library/components/feature/react/_preview/icon-top-left/square-icon-title-text"

// Hero
import HeroFeatureComponent from "../../../library/components/feature/react/_preview/HeroFeatureComponent"

// All
// Icon left
const FeatureIconLeftTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-left/icon-text.jsx")
const FeatureIconLeftTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-left/icon-text.html")

const FeatureIconLeftTitleTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-left/icon-title-text.jsx")
const FeatureIconLeftTitleTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-left/icon-title-text.html")

const FeatureIconLeftSquareTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-left/square-icon-text.jsx")
const FeatureIconLeftSquareTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-left/square-icon-text.html")

const FeatureIconLeftSquareTitleTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-left/square-icon-title-text.jsx")
const FeatureIconLeftSquareTitleTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-left/square-icon-title-text.html")

// Icon top center
const FeatureIconTopCenterTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-center/icon-text.jsx")
const FeatureIconTopCenterTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-center/icon-text.html")

const FeatureIconTopCenterTitleTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-center/icon-title-text.jsx")
const FeatureIconTopCenterTitleTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-center/icon-title-text.html")

const FeatureIconTopCenterSquareTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-center/square-icon-text.jsx")
const FeatureIconTopCenterSquareTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-center/square-icon-text.html")

const FeatureIconTopCenterSquareTitleTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-center/square-icon-title-text.jsx")
const FeatureIconTopCenterSquareTitleTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-center/square-icon-title-text.html")

// Icon top left
const FeatureIconTopLeftTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-left/icon-text.jsx")
const FeatureIconTopLeftTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-left/icon-text.html")

const FeatureIconTopLeftTitleTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-left/icon-title-text.jsx")
const FeatureIconTopLeftTitleTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-left/icon-title-text.html")

const FeatureIconTopLeftSquareTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-left/square-icon-text.jsx")
const FeatureIconTopLeftSquareTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-left/square-icon-text.html")

const FeatureIconTopLeftSquareTitleTextJsx = require("!!raw-loader!../../../library/components/feature/react/icon-top-left/square-icon-title-text.jsx")
const FeatureIconTopLeftSquareTitleTextHTML = require("!!raw-loader!../../../library/components/feature/html/icon-top-left/square-icon-title-text.html")

export default function FeaturesPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FeatureIconLeftText",
      title: "Left icon",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconLeftTitleText",
      title: "Left icon with title",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconLeftSquareText",
      title: "Left square icon",
      active_tab: 1,
    },

    {
      component_name: "FeatureIconLeftSquareTitleText",
      title: "Left square icon & title",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopCenterText",
      title: "Centered top icon",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopCenterTitleText",
      title: "Centered top icon and title",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopCenterSquareText",
      title: "Square icon centered top",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopCenterSquareTitleText",
      title: "Centered top square icon & title",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopLeftText",
      title: "Top left aligned icon",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopLeftTitleText",
      title: "Top left aligned icon & title",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopLeftSquareText",
      title: "Square icon top left aligned",
      active_tab: 1,
    },
    {
      component_name: "FeatureIconTopLeftSquareTitleText",
      title: "Top left aligned square icon & title",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Features - WindUI Component Library"
        ogtitle="Tailwind CSS Features - WindUI Component Library"
        description="The Feature component, provides a visually appealing and informative way to present essential information to the user. Built with Tailwind CSS by WindUI."
        ogdescription="The Feature component, provides a visually appealing and informative way to present essential information to the user. Built with Tailwind CSS by WindUI."
        url="components/features/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Features, Features, Feature component, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Features</h1>
          <p>
            The Feature component is designed to highlight and showcase a
            specific feature, functionality, or offering within a product,
            application, or website. It provides a visually appealing and
            informative way to present essential information about the feature
            to the user.
          </p>

          {/* Feature Hero Section */}
          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 leading-6 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroFeatureComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* One-line feature with icon on the left */}
          <h3 id="FeatureIconLeftText">
            One-line feature items with icon on the left
          </h3>
          <PreviewBlock
            id="FeatureIconLeftText"
            HtmlComponent={FeatureIconLeftTextHTML.default}
            JsxComponent={FeatureIconLeftTextJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesPreviewBlock="justify-center"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconLeftTextPreview
                FeatureIconLeftText={
                  activeTabs[0].active_tab === 1
                    ? FeatureIconLeftTextHTML.default
                    : FeatureIconLeftTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Feature items with title, text and icon on the left */}
          <h3 id="FeatureIconLeftTitleText">
            Feature items with title, text and icon on the left
          </h3>
          <PreviewBlock
            id="FeatureIconLeftTitleText"
            HtmlComponent={FeatureIconLeftTitleTextHTML.default}
            JsxComponent={FeatureIconLeftTitleTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconLeftTitleTextPreview
                FeatureIconLeftTitleText={
                  activeTabs[1].active_tab === 1
                    ? FeatureIconLeftTitleTextHTML.default
                    : FeatureIconLeftTitleTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* One-line feature items with text and square icon on the left */}
          <h3 id="FeatureIconLeftSquareText">
            One-line feature items with text and square icon on the left
          </h3>
          <PreviewBlock
            id="FeatureIconLeftSquareText"
            HtmlComponent={FeatureIconLeftSquareTextHTML.default}
            JsxComponent={FeatureIconLeftSquareTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconLeftSquareTextPreview
                FeatureIconLeftSquareText={
                  activeTabs[2].active_tab === 1
                    ? FeatureIconLeftSquareTextHTML.default
                    : FeatureIconLeftSquareTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Feature items with title, text and square icon on the left */}
          <h3 id="FeatureIconLeftSquareTitleText">
            Feature items with title, text and square icon on the left
          </h3>
          <PreviewBlock
            id="FeatureIconLeftSquareTitleText"
            HtmlComponent={FeatureIconLeftSquareTitleTextHTML.default}
            JsxComponent={FeatureIconLeftSquareTitleTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconLeftSquareTitleTextPreview
                FeatureIconLeftSquareTitleText={
                  activeTabs[3].active_tab === 1
                    ? FeatureIconLeftSquareTitleTextHTML.default
                    : FeatureIconLeftSquareTitleTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* On-line feature items with text and centered icon on the top */}
          <h3 id="FeatureIconTopCenterText">
            On-line feature items with text and top centered icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopCenterText"
            HtmlComponent={FeatureIconTopCenterTextHTML.default}
            JsxComponent={FeatureIconTopCenterTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopCenterTextPreview
                FeatureIconTopCenterText={
                  activeTabs[4].active_tab === 1
                    ? FeatureIconTopCenterTextHTML.default
                    : FeatureIconTopCenterTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Feature items with title, text and top centered icon */}
          <h3 id="FeatureIconTopCenterTitleText">
            Feature items with title, text and top centered icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopCenterTitleText"
            HtmlComponent={FeatureIconTopCenterTitleTextHTML.default}
            JsxComponent={FeatureIconTopCenterTitleTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopCenterTitleTextPreview
                FeatureIconTopCenterTitleText={
                  activeTabs[5].active_tab === 1
                    ? FeatureIconTopCenterTitleTextHTML.default
                    : FeatureIconTopCenterTitleTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* One-line feature items with text and top centered square icon */}
          <h3 id="FeatureIconTopCenterSquareText">
            One-line feature items with text and top centered square icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopCenterSquareText"
            HtmlComponent={FeatureIconTopCenterSquareTextHTML.default}
            JsxComponent={FeatureIconTopCenterSquareTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopCenterSquareTextPreview
                FeatureIconTopCenterSquareText={
                  activeTabs[6].active_tab === 1
                    ? FeatureIconTopCenterSquareTextHTML.default
                    : FeatureIconTopCenterSquareTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Feature items with title, text and top centered square icon */}
          <h3 id="FeatureIconTopCenterSquareTitleText">
            Feature items with title, text and top centered square icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopCenterSquareTitleText"
            HtmlComponent={FeatureIconTopCenterSquareTitleTextHTML.default}
            JsxComponent={FeatureIconTopCenterSquareTitleTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopCenterSquareTitleTextPreview
                FeatureIconTopCenterSquareTitleText={
                  activeTabs[7].active_tab === 1
                    ? FeatureIconTopCenterSquareTitleTextHTML.default
                    : FeatureIconTopCenterSquareTitleTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* One-line feature items with text and top left aligned icon */}
          <h3 id="FeatureIconTopLeftText">
            One-line feature items with text and top left aligned icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopLeftText"
            HtmlComponent={FeatureIconTopLeftTextHTML.default}
            JsxComponent={FeatureIconTopLeftTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopLeftTextPreview
                FeatureIconTopLeftText={
                  activeTabs[8].active_tab === 1
                    ? FeatureIconTopLeftTextHTML.default
                    : FeatureIconTopLeftTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Feature items with title, text and top left aligned icon */}
          <h3 id="FeatureIconTopLeftTitleText">
            Feature items with title, text and top left aligned icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopLeftTitleText"
            HtmlComponent={FeatureIconTopLeftTitleTextHTML.default}
            JsxComponent={FeatureIconTopLeftTitleTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopLeftTitleTextPreview
                FeatureIconTopLeftTitleText={
                  activeTabs[9].active_tab === 1
                    ? FeatureIconTopLeftTitleTextHTML.default
                    : FeatureIconTopLeftTitleTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* One-line feature items with text and top left aligned square icon */}
          <h3 id="FeatureIconTopLeftSquareText">
            One-line feature items with text and top left aligned square icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopLeftSquareText"
            HtmlComponent={FeatureIconTopLeftSquareTextHTML.default}
            JsxComponent={FeatureIconTopLeftSquareTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopLeftSquareTextPreview
                FeatureIconTopLeftSquareText={
                  activeTabs[10].active_tab === 1
                    ? FeatureIconTopLeftSquareTextHTML.default
                    : FeatureIconTopLeftSquareTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Feature items with title, text and top left aligned square icon */}
          <h3 id="FeatureIconTopLeftSquareTitleText">
            Feature items with title, text and top left aligned square icon
          </h3>
          <PreviewBlock
            id="FeatureIconTopLeftSquareTitleText"
            HtmlComponent={FeatureIconTopLeftSquareTitleTextHTML.default}
            JsxComponent={FeatureIconTopLeftSquareTitleTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FeatureIconTopLeftSquareTitleTextPreview
                FeatureIconTopLeftSquareTitleText={
                  activeTabs[11].active_tab === 1
                    ? FeatureIconTopLeftSquareTitleTextHTML.default
                    : FeatureIconTopLeftSquareTitleTextJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            The Feature components are typically used in various contexts
            throughout a user interface. They can be found on a homepage,
            landing page, product tour, or within specific sections of an
            application where features need to be highlighted. Use a
            multi-column
            <a href="/components/layouts/"> layout</a> to display a set of
            features side by side.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>The Feature component consists of the following elements:</p>
          <ul>
            <li>
              <strong>Icon:</strong> A 1.5rem (24px) in width and height icon.
              Icons can have a background color.
            </li>
            <li>
              <strong>Title:</strong> The feature title, 1.25rem (20px) in font
              size.
            </li>
            <li>
              <strong>Text:</strong> Supportive text at 1rem (16px) font size.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>Make sure that the icon is accessible,</p>
          <ul>
            <li>
              Make sure you are using an <code>alt=""</code> attribute for{" "}
              <code>&lt;img&gt;</code> elements or
            </li>
            <li>
              Ensure that you have an <code>aria-label=""</code> attribute to
              define the accessible name of inline <code>&lt;svg&gt;</code>.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
